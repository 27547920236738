import React from "react";
import "./EpilepsySurgery.css";
const EpilepsySurgery = () => {
  return (
    <div className="epilepsySurgery_parent_div">
      <h1>This is EpilepsySurgery</h1>
    </div>
  );
};

export default EpilepsySurgery;
