import React from "react";
import "./WhatWeTreat.css";
import logoOne from "../../accets/WhatWeTreat/logoOne.png";
import logoTwo from "../../accets/WhatWeTreat/logoTwo.png";
const WhatWeTreat = () => {
  return (
    <div className="what_we_treat_parent_div">
      {/* <h1 className="what_we_treat_title">Neurosurgery What We Treat</h1> */}
      <h2 className="what_we_treat_title">Treatment & Surgery</h2>

      <div className="whatWeTreat_grid_div">
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Spinal Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoOne} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Brain Tumour Operation</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Endoscopic Brain Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Skull Base Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Vascular Neuro Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Pediatric Neuro Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">
            Traumatic Braiin & Spine Surgery
          </h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Epilepsy Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Spinal Tumour</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Microdiscectomy</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Stroke Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                Maecenas eget diam at vidaeque a lorem ipsum dolro sit
                gravida.Ut hendrerit odio tortor, vr, vitae impetus stuimperdiet
                metus semper rem ipsum dorlgravida.
              </p>

              <button className="readMore_btn">Read More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeTreat;
