import React from "react";
import "./SpinalSurgery.css";
const SpinalSurgery = () => {
  return (
    <div className="spinalSurgery_parent_div">
      <h1>This is SpinalSurgery</h1>
    </div>
  );
};

export default SpinalSurgery;
