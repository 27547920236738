import React from "react";
import "./MeetOurNeurosurgons.css";
// import photoOne from "../../accets/blogImg/blogCartOne.jpg";
// import photoTwo from "../../accets/blogImg/blogCartTwo.jpg";
// import photoThree from "../../accets/blogImg/blogCartThree.jpg";
// import photoFour from "../../accets/blogImg/blogCartFour.jpg";

import photoOne from "../../accets/photoGallery/galleryPhotoOne.jpg";
import photoTwo from "../../accets/photoGallery/galleryPhotoTwo.jpg";
import photoThree from "../../accets/photoGallery/galleryPhotoThree.jpg";
import photoFour from "../../accets/photoGallery/galleryPhotoFour.jpg";
import photoFive from "../../accets/photoGallery/galleryPhotoFive.jpg";
import photoSix from "../../accets/photoGallery/galleryPhotoSix.jpg";
import photoSaven from "../../accets/photoGallery/galleryPhotoSaven.jpg";
import photoEight from "../../accets/photoGallery/galleryPhotoEight.jpg";
import photoGalleryTwentee from "../../accets/photoGallery/photoGalleryTwentee.jpg";
import photoGalleryNinteen from "../../accets/photoGallery/photoGalleryNinteen.jpg";
import galleryPhotoTen from "../../accets/photoGallery/galleryPhotoTen.jpg";
import { Link } from "react-router-dom";

const MeetOurNeurosurgons = () => {
  return (
    <div className="MeetOurNeurosurgons_parent_div">
      <h1>Photo Gallery</h1>

      <div className="meet_our_grid_div">
        <div className="meet_our_cart">
          <img src={photoGalleryTwentee} alt="" />
        </div>
        <div className="meet_our_cart">
          <img src={photoGalleryNinteen} alt="" />
        </div>
        <div className="meet_our_cart">
          <img src={photoFour} alt="" />
        </div>
        <div className="meet_our_cart">
          <img src={photoThree} alt="" />
        </div>
        <div className="meet_our_cart">
          <img src={photoOne} alt="" />
        </div>
        <div className="meet_our_cart">
          <img src={photoTwo} alt="" />
        </div>

        <div className="meet_our_cart">
          <img src={galleryPhotoTen} alt="" />
        </div>
        <div className="meet_our_cart">
          <img src={photoSix} alt="" />
        </div>
      </div>
      <Link to="/photoGallery">
        <button className="see_more_btn">See More</button>
      </Link>
    </div>
  );
};

export default MeetOurNeurosurgons;
