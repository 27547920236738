import React from "react";
import "./VideoGallery.css";
import homeVideoGalleryOne from "../../../../accets/homeVideoGalleryPhoto/homeVideoGalleryOne.jpg";
import homeVideoGalleryTwo from "../../../../accets/homeVideoGalleryPhoto/homeVideoGalleryTwo.webp";
import homeVideoGalleryThree from "../../../../accets/homeVideoGalleryPhoto/homeVideoGalleryThree.webp";
import homeVideoGalleryFour from "../../../../accets/homeVideoGalleryPhoto/homeVideoGalleryFour.webp";

import videoBannerPhoto from "../../../../accets/videoGalleryPhoto/videoGalleryPhoto.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
const VideoGallery = () => {
  const modalInfo = [
    {
      id: 1,
      name: "Seminar",
      url: "https://www.youtube.com/embed/S2d-Mvwg8eE?si=9JdqKJpThI2-ggZi",
    },

    {
      id: 2,
      name: "Speaking with patient",
      url: "https://www.youtube.com/embed/UD6TCZFe7lw?si=KVeMcYWpaml7KqNZ",
    },
    {
      id: 3,
      name: "Speaking with patients",
      url: "https://www.youtube.com/embed/TnJt1dRIUj4?si=vgrzjYn-Z73Pzyyr",
    },
    {
      id: 4,
      name: "Surgery",
      url: "https://www.youtube.com/embed/e_BVg8lYLeA?si=ibBCtYkwrTb5XOAO",
    },
  ];

  return (
    <div className="nasted_video_gall_parent_div">
      <div className="video_gallery_banner_div">
        <h1 className="video_title">Video Gallery</h1>
      </div>

      {/* <div className="nasted_video_img_parent_div">
        <div className="nasted_video_gall_grid_div">
          <div>
            <img src={homeVideoGalleryOne} alt="" />
            <FontAwesomeIcon className="faPlay_icon" icon={faPlay} />
            <p>Seminar</p>
          </div>
          <div>
            <img src={homeVideoGalleryTwo} alt="" />
            <FontAwesomeIcon className="faPlay_icon" icon={faPlay} />
            <p>Speaking with patient</p>
          </div>
          <div>
            <img src={homeVideoGalleryThree} alt="" />

            <FontAwesomeIcon className="faPlay_icon" icon={faPlay} />
            <p>Speaking with patients</p>
          </div>
          <div>
            <img src={homeVideoGalleryFour} alt="" />
            <FontAwesomeIcon className="faPlay_icon" icon={faPlay} />
            <p>Surgery</p>
          </div>
        </div>
        <div className="video_gallery_modal_div">
          <h3>Seminar</h3>
          <iframe
            className="video_gallery_modal_iframe"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/e_BVg8lYLeA?si=ibBCtYkwrTb5XOAO"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div> */}

      <div className="nasted_video_grid_div">
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://www.youtube.com/embed/G5HIZfl4i5Y?si=HY322buc4752cL0x"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://www.youtube.com/embed/UWKZE4kCk9c?si=Twu8ycUILJZpYcdb"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://www.youtube.com/embed/IOw5Mlh30qI?si=q2S0D8_PQ0CDA7TV"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://www.youtube.com/embed/lpHToQhn6_Q?si=dwTpDkkL90ggE18A"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://www.youtube.com/embed/bacjoQhnV9c?si=LLTYMfBhfKRWKt8e"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://www.youtube.com/embed/1vLtXFo6f4g?si=1Wcu1vaskLONTMx8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/KFwJm5u6cPo?si=rohW8PCyZ7PIm5re"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/ieISH8Ip85w?si=xaMH6fGBymWMl__n"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/VDdkk85m0ow?si=Z9SqDR3R12DYZj6F"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/WNdsMXp5VE0?si=cyi7cEHHm0T8PDJ5"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/Ig9kg2YJeKA?si=Dr0yr-2-WapW4T_i"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/TAe_yvGD3hU?si=BOp3hYQA0GxXrYHn"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/O5Ve71ahiKc?si=R9sq3SQSWBRZtpl9"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="video_iframe"
            // width="450"
            // height="315"
            src="https://youtube.com/embed/6Y48B25uBHM?si=63iZeGXMkH8CMQyB"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
