import React from "react";
import "./Microdiscectomy.css";
const Microdiscectomy = () => {
  return (
    <div className="microdiscectomy_parent_div">
      <h1>This is Microdiscectomy</h1>
    </div>
  );
};

export default Microdiscectomy;
