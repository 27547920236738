import React from "react";
import "./BrainTumourOperation.css";
const BrainTumourOperation = () => {
  return (
    <div className="brainTumourOperation_parent_div">
      <h1>This is BrainTumourOperation</h1>
    </div>
  );
};

export default BrainTumourOperation;
