import React from "react";
import "./VascularNeuroSurgery.css";
const VascularNeuroSurgery = () => {
  return (
    <div className="vascularNeuroSurgery_parent_div">
      <h1>This is VascularNeuroSurgery</h1>
    </div>
  );
};

export default VascularNeuroSurgery;
