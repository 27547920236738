import React from "react";
import "./OnlineAppointment.css";

const OnlineAppointment = () => {
  return (
    <div className="online_appointment_parent_div">
      {/* <div className="online_input_parent_div">
        <h3 className="get_started">Get Started Online Appointment</h3>
        <div className="online_appointment_input_div">
          <div>
            <p>Name</p>
            <input type="text" name="" id="" placeholder="Name" />
            <br />
            <p>E-Mail</p>
            <input type="email" name="" id="" placeholder="E-Mail" />
            <br />
            <p>Treatment We Offer</p>
            <select name="" id="">
              <option value="">Treatment One</option>
              <option value="">Treatment Three</option>
              <option value="">Treatment Four</option>
            </select>
          </div>

          <div>
            <p>Last Name</p>
            <input type="text" name="" id="" placeholder="Name" />
            <br />
            <p>Phone</p>
            <input type="number" name="" id="" placeholder="E-Mail" />
            <br />
            <p>Data</p>
            <input type="date" name="" id="" />
          </div>
        </div>
        <button className="Request_button">Request For Appointment</button>
      </div> */}
      {/* <div className="Online_appointment_time_div">
        <h3>Opening Hours</h3>
        <div>
          <p>
            Monday: <span>06:00 Am - 08:00 Pm</span>
          </p>
          <div className="online_blank_div"></div>
          <p>
            Tuesday: <span>06:00 Am - 08:00 Pm</span>
          </p>
          <div className="online_blank_div"></div>
          <p>
            Wednesday: <span>06:00 Am - 08:00 Pm</span>
          </p>
          <div className="online_blank_div"></div>
          <p>
            Thursday: <span>06:00 Am - 08:00 Pm</span>
          </p>
          <div className="online_blank_div"></div>
          <p>
            Friday: <span>06:00 Am - 08:00 Pm</span>
          </p>
          <div className="online_blank_div"></div>
          <p>
            Saturday: <span>08:00 Am - 04:00 Pm</span>
          </p>
          <div className="online_blank_div"></div>
          <p>
            Emergancy : <span>(anytime call us)</span>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default OnlineAppointment;
