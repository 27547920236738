import React from "react";
import "./EndoscopicBrainSurgery.css";
const EndoscopicBrainSurgery = () => {
  return (
    <div className="endoscopicBrainSurgery_parent_div">
      <h1>This is EndoscopicBrainSurgery</h1>
    </div>
  );
};

export default EndoscopicBrainSurgery;
