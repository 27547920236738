import React from "react";
import "./SpinalTumour.css";
const SpinalTumour = () => {
  return (
    <div className="spinalTumour_parent_div">
      <h1>This is SpinalTumour</h1>
    </div>
  );
};

export default SpinalTumour;
