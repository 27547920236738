import React from "react";
import "./Blogs.css";
import Blog from "./Blog";
import blogCartOne from "../../accets/blogImg/blogCartOne.jpg";
import blogCartTwo from "../../accets/blogImg/blogCartTwo.jpg";
import blogCartThree from "../../accets/blogImg/blogCartThree.jpg";
import blogCartFour from "../../accets/blogImg/blogCartFour.jpg";
import blogCartFive from "../../accets/blogImg/blogCartFive.jpg";
import blogCartSix from "../../accets/blogImg/blogCartSix.jpg";
import blogCartUserImg from "../../accets/blogImg/blogCartUserImg.jpg";

const Blogs = () => {
  const blogCarts = [
    {
      id: 1,
      img: blogCartOne,
      img2: blogCartUserImg,
      name: "Peter Packer",
      date: "21 July 2021",
      title: "Dental Care for Women is very important",
    },
    {
      id: 2,
      img: blogCartTwo,
      img2: blogCartUserImg,
      name: "Kalina",
      date: "21 July 2021",
      title: "Dental Care for Women is very important",
    },
    {
      id: 3,
      img: blogCartThree,
      img2: blogCartUserImg,
      name: "John deo",
      date: "21 July 2021",
      title: "Dental Care for Women is very important",
    },
    {
      id: 4,
      img: blogCartFour,
      img2: blogCartUserImg,
      name: "John deo",
      date: "21 July 2021",
      title: "Dental Care for Women is very important",
    },
    {
      id: 5,
      img: blogCartFive,
      img2: blogCartUserImg,
      name: "John deo",
      date: "21 July 2021",
      title: "Dental Care for Women is very important",
    },
    {
      id: 6,
      img: blogCartSix,
      img2: blogCartUserImg,
      name: "John deo",
      date: "21 July 2021",
      title: "Dental Care for Women is very important",
    },
  ];
  return (
    <div className="blogs_parent_div">
      <div className="blgo_title_div">
        <h1> Blog</h1>
      </div>
      <div className="blog_grid_div">
        {blogCarts.map((blog) => (
          <Blog key={blog.id} blog={blog}></Blog>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
