import React from "react";
import "./Home.css";

import AboutNeurosurgeonDrAtiqulIslam from "../AboutNeurosurgeonDrAtiqulIslam/AboutNeurosurgeonDrAtiqulIslam";
import HomeVideoGallery from "../HomeVideoGallery/HomeVideoGallery";
import Banner from "../Banner/Banner";
import WhatWeTreat from "../WhatWeTreat/WhatWeTreat";
import MeetOurNeurosurgons from "../MeetOurNeurosurgons/MeetOurNeurosurgons";
import OnlineAppointment from "../OnlineAppointment/OnlineAppointment";
import NeurosurgeryLocations from "../NeurosurgeryLocations/NeurosurgeryLocations";
const Home = () => {
  return (
    <div id="home_parent_div" className="home_parent_div">
      <Banner></Banner>
      <div id="about-section">
        <AboutNeurosurgeonDrAtiqulIslam></AboutNeurosurgeonDrAtiqulIslam>
      </div>
      <WhatWeTreat></WhatWeTreat>
      {/* <PatientNeeds></PatientNeeds> */}
      <MeetOurNeurosurgons></MeetOurNeurosurgons>
      <OnlineAppointment></OnlineAppointment>
      <HomeVideoGallery></HomeVideoGallery>
      <NeurosurgeryLocations></NeurosurgeryLocations>
      {/* ====================================================== */}
      {/* <div className="home_secont_parent_div">
        <div>
          <div className="home_child_div">
            <div className="banner_left_div">
              <div className="solid_img_div">
                <div>
                  <img className="circulePhoto" src={circulePhoto} alt="" />
                </div>
                <div>
                  <img className="squarePhoto" src={squarePhoto} alt="" />
                </div>
              </div>
              <div className="tryAnglePhoto_flex_div">
                <div>
                  <img className="tryAnglePhoto" src={tryAnglePhoto} alt="" />
                </div>
                <div>
                  <img className="govtPhoto" src={govtPhoto} alt="" />
                  <h1 className="dr_Name">
                    Dr<span className="small-square">&#x25A0;</span> K
                    <span className="small-square">&#x25A0;</span> M
                    <span className="small-square">&#x25A0;</span> Atiqul Islam
                  </h1>

                  <h5>Brain & Spine Surgeon</h5>
                  <h5>MBBS (DMC), BCS (Health)</h5>
                  <h5>MS (Neurosurgery)</h5>
                  <h5>Dept of Neurosurgery</h5>
                  <h5>
                    National Institute of Neuroscience & Hospital Agargaon,
                    Dhaka
                  </h5>
                </div>
              </div>
            </div>
            <div className="banner_right_div">
              <img className="doctor_img" src={doctorPhoto} alt="" />
            </div>
          </div>
          <div className="webPhoto_div">
            <img className="webPhoto" src={webPhoto} alt="" />
          </div>
          <img className="plusPhoto" src={plusPhoto} alt="" />
          <img className="waterPhoto" src={waterPhoto} alt="" />
        </div>
      </div> */}
      {/* NAVIGATION BAR END */}
      {/* <HomeGallery></HomeGallery> */}
      {/* <PatientsAfterOperation></PatientsAfterOperation> */}
    </div>
  );
};

export default Home;
