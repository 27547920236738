import React from "react";
import "./PediatricNeuroSurgery.css";
const PediatricNeuroSurgery = () => {
  return (
    <div className="pediatricNeuroSurgery_parent_div">
      <h1>This is PediatricNeuroSurgery</h1>
    </div>
  );
};

export default PediatricNeuroSurgery;
