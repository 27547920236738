import React from "react";
import "./TraumaticBrainAndSpineSurgery.css";
const TraumaticBrainAndSpineSurgery = () => {
  return (
    <div className="traumaticBrain_parent_div">
      <h1>This is TraumaticBrainAndSpineSurgery</h1>
    </div>
  );
};

export default TraumaticBrainAndSpineSurgery;
