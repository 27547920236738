import React from "react";
import "./Blog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Blog = ({ blog }) => {
  return (
    <div className="blog_cart_parent_div">
      <img className="blog_img" src={blog.img} alt="" />
      <div>
        <div className="blog_img2_flex_parent_div">
          <div className="blog_img2_flex_div">
            <img className="blog_img2" src={blog.img2} alt="" />
            <span className="blog_name">{blog.name}</span>
          </div>
          <div className="blog_date_flex_div">
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span>{blog.date}</span>
          </div>
        </div>
        <h3>{blog.title}</h3>
        <button className="blog_btn">
          <Link>Read More</Link>
          <span className="blog_right_angle_icon">
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Blog;
