import React from "react";
import "./Footer.css";
import footerLogo from "../../accets/Logo/logo.png";
import footerBusinessCart from "../../accets/footerPhoto/footerBusinessCart.png";
import facebook from "../../accets/socialMediaImg/facebook.png";
import youtube from "../../accets/socialMediaImg/youtube.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_parent_div">
      <div className="footer_grid_div">
        <div className="footer_first_cart_div">
          <img className="footerLogo" src={footerLogo} alt="" />
          <div className="footer_email_div">
            {/* <FontAwesomeIcon icon={faPaperPlane} /> */}
            <FontAwesomeIcon className="footerFaEnvelope " icon={faEnvelope} />
            <p>kazidmc@gmail.com</p>
          </div>
          <div className="footer_socialMedia_div">
            <Link
              to="https://www.facebook.com/Neurosurgeondratique"
              target="_blank"
            >
              <img src={facebook} alt="" />
            </Link>
            <Link
              to="https://www.youtube.com/channel/UCTt7e2oBOpwI56ANOwQlKag"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </Link>
          </div>
        </div>

        <div className="footer_last_cart_div">
          <h3 className="site_map_title">Site Map</h3>
          {/* <img className="footerSiteMapPhoto" src={footerSiteMap} alt="" /> */}
          <div className="footer_siteMap_link_div">
            <Link>About Dr. Atique</Link>
            <Link>Treatments</Link>

            <Link>Photo Gallery</Link>

            <Link>Patients' Feedback</Link>
          </div>
        </div>

        <div>
          {/* <img className="footerAdBanner" src={footerAdBanner} alt="" /> */}
          <img className="footerBusinessCart" src={footerBusinessCart} alt="" />
        </div>
      </div>

      <div>
        <div className="footer_Copyright_div">
          <p>
            Copyright © || Neurosurgeon Dr. Atique. <span>IT Partner -</span>{" "}
            <Link to="https://abaacorp.com/" target="_blank">
              <span>Abaacorp.com</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
