import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Main from "./LayOut/Main";
import Home from "./component/Home/Home";
import Blog from "./component/Blog/Blog";
import BrainTumourOperation from "./component/TreatmentsAndSurgery/NastedRoute/BrainTumourOperation/BrainTumourOperation";
import EndoscopicBrainSurgery from "./component/TreatmentsAndSurgery/NastedRoute/EndoscopicBrainSurgery/EndoscopicBrainSurgery";
import SkullBaseSurgery from "./component/TreatmentsAndSurgery/NastedRoute/SkullBaseSurgery/SkullBaseSurgery";
import VascularNeuroSurgery from "./component/TreatmentsAndSurgery/NastedRoute/VascularNeuroSurgery/VascularNeuroSurgery";
import PediatricNeuroSurgery from "./component/TreatmentsAndSurgery/NastedRoute/PediatricNeuroSurgery/PediatricNeuroSurgery";
import SpinalSurgery from "./component/TreatmentsAndSurgery/NastedRoute/SpinalSurgery/SpinalSurgery";
import TraumaticBrainAndSpineSurgery from "./component/TreatmentsAndSurgery/NastedRoute/TraumaticBrainAndSpineSurgery/TraumaticBrainAndSpineSurgery";
import EpilepsySurgery from "./component/TreatmentsAndSurgery/NastedRoute/EpilepsySurgery/EpilepsySurgery";
import SpinalTumour from "./component/TreatmentsAndSurgery/NastedRoute/SpinalTumour/SpinalTumour";
import Microdiscectomy from "./component/TreatmentsAndSurgery/NastedRoute/Microdiscectomy/Microdiscectomy";
import StrokeSurgery from "./component/TreatmentsAndSurgery/NastedRoute/StrokeSurgery/StrokeSurgery";
import PhotoGallery from "./component/Gallery/NastedGallery/PhotoGallery/PhotoGallery";
import VideoGallery from "./component/Gallery/NastedGallery/VideoGallery/VideoGallery";
import Blogs from "./component/Blog/Blogs";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main></Main>,
      children: [
        {
          path: "/",
          element: <Home></Home>,
        },
        {
          path: "/home",
          element: <Home></Home>,
        },

        // NASTED NAVIGATION MENUE START //
        {
          path: "/brainTumourOperation",
          element: <BrainTumourOperation></BrainTumourOperation>,
        },
        {
          path: "/endoscopicBrainSurgery",
          element: <EndoscopicBrainSurgery></EndoscopicBrainSurgery>,
        },
        {
          path: "/skullBaseSurgery",
          element: <SkullBaseSurgery></SkullBaseSurgery>,
        },
        {
          path: "/vascularNeuroSurgery",
          element: <VascularNeuroSurgery></VascularNeuroSurgery>,
        },
        {
          path: "/pediatricNeuroSurgery",
          element: <PediatricNeuroSurgery></PediatricNeuroSurgery>,
        },
        {
          path: "/spinalSurgery",
          element: <SpinalSurgery></SpinalSurgery>,
        },
        {
          path: "/traumaticBrainAndSpineSurgery",
          element: (
            <TraumaticBrainAndSpineSurgery></TraumaticBrainAndSpineSurgery>
          ),
        },
        {
          path: "/epilepsySurgery",
          element: <EpilepsySurgery></EpilepsySurgery>,
        },
        {
          path: "/spinalTumour",
          element: <SpinalTumour></SpinalTumour>,
        },
        {
          path: "/microdiscectomy",
          element: <Microdiscectomy></Microdiscectomy>,
        },
        {
          path: "/strokeSurgery",
          element: <StrokeSurgery></StrokeSurgery>,
        },
        {
          path: "/photoGallery",
          element: <PhotoGallery></PhotoGallery>,
        },
        {
          path: "/videoGallery",
          element: <VideoGallery></VideoGallery>,
        },
        // NASTED NAVIGATION MENUE END //
        {
          path: "/blog",
          element: <Blog></Blog>,
        },
        {
          path: "/blogs",
          element: <Blogs></Blogs>,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
