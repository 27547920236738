import React, { useEffect } from "react";
import NavigationBar from "../component/NavigationBar/NavigationBar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../component/Footer/Footer";

const Main = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div id="forModal">
      <NavigationBar></NavigationBar>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  );
};

export default Main;
